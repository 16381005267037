import Seasons from 'seasons-dates'

function determineSeason(date?: Date) {
  if (!date) return undefined

  let dateSeason: number = 0

  const seasons = new Seasons(date.getFullYear()),
    dateYearSeasons = [
      new Date(`${date.getFullYear()}`),
      new Date(seasons.spring),
      new Date(seasons.summer),
      new Date(seasons.autumn),
      new Date(seasons.winter),
    ]

  dateYearSeasons.forEach((season, i: number, a: Date[]) => {
    if (date >= season) dateSeason = i
  })

  return dateSeason
}

function printSeason(index?: number) {
  switch (index) {
    case 0:
      return `Winter`
    case 1:
      return `Spring`
    case 2:
      return `Summer`
    case 3:
      return `Autumn`
    case 4:
      return `Winter`
    default:
      return undefined
  }
}

/*
 * `Spring 2016`: Start and end dates fall within one season of one year
 * `Spring–Winter 2016: Start and end dates fall within different season of the same year
 * `Spring 2016–Summer 2017: Start and end dates fall within different years
 * `Spring 2016–`: End date not set (Alternatively, `Ongoing`)
 * `Ongoing`: Start date not set
 */
function determineDuration(start?: Date, end?: Date) {
  const startSeason = determineSeason(start),
    startYear = start?.getFullYear() || undefined,
    endSeason = determineSeason(end),
    endYear = end?.getFullYear() || undefined

  // No information available
  if (!(start || end)) return `Ongoing`

  // End indeterminate
  // Season Year– (Ongoing)
  if (!end) return `${printSeason(startSeason)} ${startYear}–`

  // Ended the year it began
  if (endYear === startYear) {
    // Season Year
    if (endSeason === startSeason)
      return `${printSeason(startSeason)} ${startYear}`
    // Season–Season Year
    return `${printSeason(startSeason)}–${printSeason(endSeason)} ${startYear}`
  }

  // Ended a different year
  return `${printSeason(startSeason)} ${startYear}–${printSeason(
    endSeason
  )} ${endYear}`
}

export { determineDuration }
