import { useContext } from 'react'
import { PreviewContext } from '../context'

function firstKey(object: any) {
  return object && Object.keys(object)[0]
}

function objectKey(object: any, key?: string) {
  const first = firstKey(object)
  return first && key && object[first][key]
}

function usePreview() {
  return useContext(PreviewContext)
}

function usePreviewData(staticData: any): any {
  const { exists, mergePrismicPreviewData, previewData } = usePreview()

  // Return early if no preview exists
  if (!exists) return staticData

  const staticKey = firstKey(staticData),
    previewKey = firstKey(previewData),
    staticUid = objectKey(staticData, `uid`),
    previewUid = objectKey(previewData, `uid`),
    appendedData = Object.assign({}, staticData),
    mergedData = mergePrismicPreviewData({ staticData, previewData })

  // Append preview in certain cases unhandled by gatsby-source-prismic:
  // E.g., previewData is for `prismicProject` and staticData is for `allPrismicProject`.
  if (
    staticKey &&
    previewKey &&
    staticKey ===
      `all${previewKey.substr(0, 1).toUpperCase()}${previewKey.substr(1)}`
  ) {
    const edgeIndex = appendedData[staticKey].edges.findIndex(
      (edge: any) => edge.node.uid === previewUid
    )

    if (edgeIndex === -1)
      appendedData[staticKey].edges.push({ node: previewData[previewKey] })
    else
      appendedData[staticKey].edges.splice(edgeIndex, 1, {
        node: previewData[previewKey],
      })

    return appendedData
  }

  // mergePrismicPreviewData will merge objects with different ids if they are of the same type.
  // We want to prevent this from occurring since it leads to all projects being overwritten during preview
  return !staticUid || staticUid === previewUid ? mergedData : staticData
}

export default usePreviewData
export { usePreview, usePreviewData }
