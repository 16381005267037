import { useMemo } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import {
  PrismicProject,
  PrismicProjectConnection,
  PrismicProjectEdge,
} from '../../graphql-types'
import { useLocation, WindowLocation } from '@reach/router'
import { usePreviewData } from './usePreview'
import { determineDuration, determineProjectIndex } from '../utilities'

const prismicProjectBodyMediaItemTypeFragment = graphql`
  fragment PrismicProjectBodyMediaItemType on PrismicProjectBodyMediaItemType {
    image {
      alt
      copyright
      dimensions {
        height
        width
      }
      fluid(
        srcSetBreakpoints: [200, 400, 600, 800, 1200, 1600, 2000, 2400, 2800]
      ) {
        ...GatsbyPrismicImageFluid
      }
      thumbnails
      url
    }
    text {
      html
      raw
      text
    }
    text_position
    text_size
  }
`

const prismicProjectBodyMediaFragment = graphql`
  fragment PrismicProjectBodyMedia on PrismicProjectBodyMedia {
    id
    items {
      ...PrismicProjectBodyMediaItemType
    }
    primary {
      align_items
      centered
    }
  }
`

const prismicProjectFragment = graphql`
  fragment PrismicProject on PrismicProject {
    data {
      body {
        ... on PrismicProjectBodyMedia {
          ...PrismicProjectBodyMedia
        }
      }
      background_color
      categories {
        category
      }
      end_date
      featured_image {
        alt
        copyright
        fluid(
          srcSetBreakpoints: [200, 400, 600, 800, 1200, 1600, 2000, 2400, 2800]
        ) {
          ...GatsbyPrismicImageFluid
        }
        url
      }
      meta_description
      start_date
      title
    }
    id
    prismicId
    uid
  }
`

const allPrismicProjectQuery = graphql`
  query allPrismicProject {
    allPrismicProject {
      edges {
        node {
          ...PrismicProject
        }
      }
    }
  }
`

interface AllPrismicProject {
  allPrismicProject: PrismicProjectConnection
}

function useAllProjects(): AllPrismicProject {
  const staticData: AllPrismicProject = useStaticQuery(allPrismicProjectQuery),
    mergedData: AllPrismicProject = usePreviewData(staticData)

  return mergedData
}

function useAllSortedProjects() {
  const { allPrismicProject } = useAllProjects()

  return {
    allPrismicProject: {
      edges: allPrismicProject?.edges
        ?.filter((edge: PrismicProjectEdge) => edge && edge.node)
        .sort((a: PrismicProjectEdge, b: PrismicProjectEdge) => {
          if (a.node.data?.title && b.node.data?.title)
            return a.node.data.title.toLowerCase() <
              b.node.data.title.toLowerCase()
              ? -1
              : 1
          else if (a.node.data?.title) return -1
          else if (b.node.data?.title) return 1
          else return 0
        })
        .sort((a: PrismicProjectEdge, b: PrismicProjectEdge) => {
          if (a.node.data?.start_date && b.node.data?.start_date)
            return a.node.data.start_date > b.node.data.start_date ? -1 : 1
          else if (a.node.data?.start_date) return -1
          else if (b.node.data?.start_date) return 1
          else return 0
        }),
    },
  }
}

function useAllCategoryProjects(
  category: string
): { edges: PrismicProjectEdge[] } {
  const { allPrismicProject } = useAllSortedProjects()

  if (category && category !== `*`)
    return {
      edges: allPrismicProject.edges.filter(
        (edge: any) =>
          edge?.node?.data?.categories?.findIndex(
            (cat: any) => cat?.category === category
          ) > -1
      ),
    }

  return allPrismicProject
}

function useAllCategoryProjectsUids(category: string) {
  const allPrismicProject = useAllCategoryProjects(category)
  return allPrismicProject?.edges?.map(
    (edge) => (edge?.node as PrismicProject)?.uid
  )
}

function useAllCategoryProjectsIndex(category: string, uid?: string) {
  const uids = useAllCategoryProjectsUids(category),
    { pathname } = useLocation()

  return determineProjectIndex(uids, uid ? `/projects/${uid}` : pathname)
}

function useAllCategoryProjectsLength(category: string) {
  const allPrismicProject = useAllCategoryProjects(category)
  return allPrismicProject?.edges?.length ?? 0
}

function useProject(uid: any): PrismicProject | undefined {
  const { allPrismicProject } = useAllProjects(),
    // Remove trailing slashes
    uidSearch = uid?.replace(/(\/)$/, ``)

  return useMemo(
    () =>
      allPrismicProject?.edges?.find(
        (edge: PrismicProjectEdge): boolean => edge?.node?.uid === uidSearch
      )?.node,
    [allPrismicProject, uidSearch]
  )
}

function useAllCategoryProjectsSiblings(category: string): Siblings {
  const uids = useAllCategoryProjectsUids(category),
    index = useAllCategoryProjectsIndex(category)

  let siblings: Siblings = {
    previous: undefined,
    next: undefined,
  }

  switch (index) {
    case -1:
      break
    case 0:
      siblings.previous = uids[uids.length - 1]
      siblings.next = index + 1 === uids.length ? uids[0] : uids[index + 1]
      break
    default:
      siblings.previous = uids[index - 1]
      siblings.next = index + 1 === uids.length ? uids[0] : uids[index + 1]
      break
  }

  return siblings
}

function useProjectCategories(uid: any) {
  const project = useProject(uid),
    categories = project?.data?.categories
      ?.map((category) => category?.category)
      ?.sort()

  return categories || []
}

export interface ProjectsContext {
  context: string
}

function useProjectDuration(uid: any) {
  const project = useProject(uid),
    start = project?.data?.start_date
      ? new Date(`${project?.data.start_date}T00:00:00.000+00:00`)
      : undefined,
    end = project?.data?.end_date
      ? new Date(`${project?.data.end_date}T00:00:00.000+00:00`)
      : undefined

  return useMemo(() => determineDuration(start, end), [start, end]) || ``
}

function useProjectsContext(): ProjectsContext {
  const { state }: WindowLocation & { state: any } = useLocation()

  return {
    context: state?.context ? state.context : `All`,
  }
}

function useContextualProjectsIndex() {
  const { context } = useProjectsContext(),
    categoryProjectsIndex = useAllCategoryProjectsIndex(
      context === `All` ? `*` : context ?? `*`
    )

  return categoryProjectsIndex
}

function useContextualProjectsLength() {
  const { context } = useProjectsContext(),
    categoryProjectsLength = useAllCategoryProjectsLength(
      context === `All` ? `*` : context ?? `*`
    )

  return categoryProjectsLength
}

function useContextualProjectsSiblings() {
  const { context } = useProjectsContext(),
    categoryProjectsSiblings = useAllCategoryProjectsSiblings(
      context === `All` ? `*` : context ?? `*`
    )

  return categoryProjectsSiblings
}

function useCurrentProject(): PrismicProject | undefined {
  const { pathname } = useLocation(),
    locationUid = pathname.replace(`/projects/`, ``)

  return useProject(locationUid)
}

function useAllCategories(): string[] {
  const { allPrismicProject } = useAllSortedProjects()
  let allPrismicProjectCategories: string[] = []
  allPrismicProject?.edges?.forEach((edge: PrismicProjectEdge): void => {
    edge?.node?.data?.categories?.forEach((category) => {
      if (
        !category?.category ||
        allPrismicProjectCategories.indexOf(category?.category) > -1
      )
        return
      allPrismicProjectCategories.push(category?.category)
    })
  })
  return allPrismicProjectCategories
}

function useAllProjectsIndex(uid?: string) {
  const uids = useAllProjectsUids(),
    { pathname } = useLocation()

  return determineProjectIndex(uids, uid ? `/projects/${uid}` : pathname)
}

function useAllProjectsLength() {
  const { allPrismicProject } = useAllSortedProjects()
  return allPrismicProject?.edges?.length ?? 0
}

export interface Siblings {
  previous: string | null | undefined
  next: string | null | undefined
}

function useAllProjectsSiblings(): Siblings {
  const uids = useAllProjectsUids(),
    index = useAllProjectsIndex()

  let siblings: Siblings = {
    previous: undefined,
    next: undefined,
  }

  switch (index) {
    case -1:
      break
    case 0:
      siblings.previous = uids[uids.length - 1]
      siblings.next = uids[index + 1]
      break
    default:
      siblings.previous = uids[index - 1]
      siblings.next = index + 1 === uids.length ? uids[0] : uids[index + 1]
      break
  }

  return siblings
}

function useAllProjectsUids() {
  const { allPrismicProject } = useAllSortedProjects()
  return allPrismicProject?.edges?.map(
    (edge) => (edge?.node as PrismicProject)?.uid
  )
}

export {
  allPrismicProjectQuery,
  prismicProjectBodyMediaFragment,
  prismicProjectBodyMediaItemTypeFragment,
  prismicProjectFragment,
  useAllCategories,
  useAllCategoryProjects,
  useAllProjects,
  useAllProjectsIndex,
  useAllProjectsLength,
  useAllProjectsSiblings,
  useAllProjectsUids,
  useContextualProjectsIndex,
  useContextualProjectsLength,
  useContextualProjectsSiblings,
  useCurrentProject,
  useProject,
  useProjectCategories,
  useProjectDuration,
  useProjectsContext,
}
