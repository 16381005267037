import React, { ReactElement, useState, useContext } from 'react'
import { PreviewContext } from '../context'
import { Layout } from './layout'

const Wrapper = ({ element, props }: { element: ReactElement; props: any }) => {
  const [context, setContext] = useState(useContext(PreviewContext))

  // Supply preview context for all
  return (
    <PreviewContext.Provider value={{ ...context, setContext }}>
      <Layout {...props}>{element}</Layout>
    </PreviewContext.Provider>
  )
}

export default Wrapper
export { Wrapper }
