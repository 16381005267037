import React, { KeyboardEvent } from 'react'
import Img, { FluidObject, GatsbyImageProps } from 'gatsby-image'
import { Logo } from './logo'
import { PrismicMetadataSplashPagesGroupType } from '../../graphql-types'
import { useEventListener } from '../hooks/useEventListener'

const Splash = ({
  dismiss,
  load,
  splashPage,
}: {
  dismiss: () => void
  load: () => void
  splashPage: PrismicMetadataSplashPagesGroupType | null | undefined
}) => {
  const color = splashPage?.logo_color,
    fluid = splashPage?.image?.fluid
      ? (splashPage?.image?.fluid as FluidObject)
      : (splashPage?.image?.localFile?.childImageSharp?.fluid as FluidObject),
    imageProps: GatsbyImageProps = {
      fluid: fluid,
      fadeIn: false,
      loading: `eager`,
      onLoad: load,
    },
    logoStyle = { fill: color },
    dismissKey = (e: Event) => {
      const ke = (e as unknown) as KeyboardEvent
      if (ke.key) {
        if ([` `, `Escape`, `Enter`].indexOf(ke.key) > -1) {
          dismiss()
        }
      }
    }

  useEventListener(`click`, dismiss)
  useEventListener(`keydown`, dismissKey)

  // Set Img sizes based on styling
  if (fluid && fluid.sizes) fluid.sizes = `100vw`

  return (
    <div
      className="fixed w-full min-h-screen flex items-center justify-center z-30"
      style={{
        cursor: `pointer`,
        minHeight: `-webkit-fill-available`,
      }}
    >
      <Logo
        className="w-full m-24 z-10 pointer-events-none"
        style={logoStyle}
      />
      <div className="absolute w-full h-full">
        {fluid && <Img className="h-full" {...imageProps} />}
      </div>
    </div>
  )
}

export default Splash
export { Splash }
