import React, { useRef, useState } from 'react'
import MailchimpSubscribe, { EmailFormFields } from 'react-mailchimp-subscribe'
import isEmail from 'validator/es/lib/isEmail'
import { Sidebar } from './sidebar'

const Subscribe = () => {
  const url = `https://lukeedwardhall.us20.list-manage.com/subscribe/post-json?u=393339e054123e0d9b6ad02f8&id=3e1b243c4c`

  return (
    <MailchimpSubscribe
      url={url}
      render={({ subscribe, status, message }) => (
        <Sidebar
          id="newsletter"
          options={{
            path: `/`,
            expires: status === `success` ? 365 : 7,
          }}
        >
          <p>
            <label htmlFor="email">Subscribe to Luke’s newsletter:</label>
          </p>
          <SubscribeForm
            status={status}
            message={message}
            subscribe={(data: EmailFormFields) => subscribe(data)}
          />
        </Sidebar>
      )}
    />
  )
}

const SubscribeForm = ({
  status,
  message,
  subscribe,
}: {
  status: 'success' | 'error' | 'sending' | null
  message: string | null | Error
  subscribe: (data: EmailFormFields) => void
}) => {
  let email = useRef<HTMLInputElement | null>(null),
    [invalid, setInvalid] = useState(false)

  const messageContent =
      typeof message === `string` ? message : message ? message?.message : null,
    submit = () => {
      if (!email?.current?.value) return

      if (isEmail(email.current.value)) {
        if (invalid) setInvalid(false)
        subscribe({ EMAIL: email.current.value })
      } else {
        if (!invalid) setInvalid(true)
      }
    }

  let details = undefined
  if (messageContent && messageContent.indexOf(`already subscribed`) > -1)
    details = `You're already on the list!`
  if (messageContent && messageContent.toLowerCase().indexOf(`too many`) > -1)
    details = `Too many attempts, please try again later.`

  return (
    <>
      {invalid && (
        <p className="my-2">
          The email you entered doesn’t appear to be valid.
        </p>
      )}
      {status === `sending` && <p className="my-2">Just a second.</p>}
      {status === `error` && (
        <p className="my-2">
          {details ?? `Sorry, something went wrong. Please try again!`}
        </p>
      )}
      {status === `success` && (
        <p className="my-2">Success! Thank you for subscribing.</p>
      )}

      <div className="flex items-baseline">
        <input
          id="email"
          ref={email}
          className="flex-auto rounded-none"
          type="email"
          placeholder="Enter your email address"
        />
        <button className="border" onClick={submit}>
          Submit
        </button>
      </div>
    </>
  )
}

export default Subscribe
export { Subscribe }
