function linkResolver(doc: any) {
  switch (doc.type) {
    case 'metadata':
      return `/`
    case 'page':
      return doc.uid !== `index` ? `/${doc.uid}` : `/`
    default:
      return `/${doc.type}s/${doc.uid}`
  }
}

export { linkResolver }
