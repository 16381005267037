/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from 'react'
import { Wrapper } from './src/components/wrapper'
import './src/css/index.css'

export const wrapPageElement = (params) => {
  return <Wrapper {...params} />
}
