import React from 'react'
import { useMetadata } from '../hooks/useMetadata'

const Footer = () => {
  const { prismicMetadata } = useMetadata(),
    footerContent = prismicMetadata?.data?.footer_content?.html

  return footerContent ? (
    <footer className="bg-inherit">
      <div
        className="p-16 min-h-screen leading-tight italic flex flex-col justify-center align-center text-center"
        style={{
          fontSize: `5vw`,
        }}
        dangerouslySetInnerHTML={{
          __html: prismicMetadata?.data?.footer_content?.html ?? ``,
        }}
      />
    </footer>
  ) : null
}

export default Footer
export { Footer }
