import BellRegularWoff from './Bell-Regular.woff'
import BellRegularWoff2 from './Bell-Regular.woff2'
import BellItalicWoff from './Bell-Italic.woff'
import BellItalicWoff2 from './Bell-Italic.woff2'

import MarfaRegularWoff from './ABCMarfa-Regular.woff'
import MarfaRegularWoff2 from './ABCMarfa-Regular.woff2'
import MarfaItalicWoff from './ABCMarfa-Italic.woff'
import MarfaItalicWoff2 from './ABCMarfa-Italic.woff2'

const fonts: { [key: string]: any } = {
  Bell: {
    400: {
      normal: {
        woff: BellRegularWoff,
        woff2: BellRegularWoff2,
      },
      italic: {
        woff: BellItalicWoff,
        woff2: BellItalicWoff2,
      },
    },
  },
  Marfa: {
    400: {
      normal: {
        woff: MarfaRegularWoff,
        woff2: MarfaRegularWoff2,
      },
      italic: {
        woff: MarfaItalicWoff,
        woff2: MarfaItalicWoff2,
      },
    },
  },
}

export { Fontface } from './fontface'
export { fonts }
