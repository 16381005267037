import React from 'react'
import { useCookie } from '../hooks/useCookie'
import { CookieAttributes } from 'js-cookie'
import { usePortal } from '../hooks/usePortal'

type ReturnsJSXElement = (
  close: (override?: CookieAttributes) => void
) => JSX.Element | JSX.Element[]

const Sidebar = ({
  children,
  options = {
    path: `/`,
    expires: 0,
  },
  id,
}: {
  id: string
  children: JSX.Element | JSX.Element[] | ReturnsJSXElement
  options?: CookieAttributes
}) => {
  const [open, setOpen] = useCookie(id, true, options),
    close = (override?: CookieAttributes, callback?: () => void) => {
      if (open) {
        console.log(options, override, !override?.type && override)
        setOpen(false, !override?.type && override)
        if (callback) callback()
      }
    },
    sidebar = (
      <div
        className={`sidebar-container pointer-events-auto m-4 mt-0 w-full sm:w-3/4 md:w-1/2 xl:w-1/4 ${
          open ? `open` : `closed`
        }`}
      >
        <div
          className={`sidebar relative ml-8 sm:ml-4 p-4 pr-12 shadow text-xs text-white`}
        >
          <button
            className="absolute top-0 right-0 leading-0"
            style={{ fontSize: `1.35rem`, width: `34px`, height: `34px` }}
            onClick={close}
          >
            &times;
          </button>
          {typeof children === `function` ? children(close) : children}
        </div>
      </div>
    )

  return (
    usePortal(sidebar, {
      className: `fixed z-20 pointer-events-none inset-0 flex flex-col items-end justify-end`,
    }) || null
  )
}

export default Sidebar
export { Sidebar }
