import { ReactNode } from 'react'
import { createPortal } from 'react-dom'

function usePortal(
  child: ReactNode,
  { id = `portal-root`, className = ``, style = `` } = { id: `portal-root` }
) {
  if (typeof document === `undefined`) return

  // Identify root
  let root = id
    ? document.querySelector(`#${id}`)
    : className
    ? document.querySelector(`.${className.replace(/\s/g, `.`)}`)
    : null

  // Create root if it doesn't exist
  if (!root) root = document.createElement(`div`)

  root.id = id
  root.className = className
  root.setAttribute(`style`, style)

  // Append root if it is unattached
  if (!root.parentElement) document.body.appendChild(root)

  return createPortal(child, root as Element)
}

export { usePortal }
