import React, { useCallback, useRef, useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { Logo } from './logo'
import { useMetadata } from '../hooks/useMetadata'
import {
  useAllCategories,
  useContextualProjectsIndex,
  useContextualProjectsLength,
  useContextualProjectsSiblings,
  useCurrentProject,
  useProjectCategories,
  useProjectDuration,
  useProjectsContext,
} from '../hooks/useAllProjects'
import { useEventListener } from '../hooks/useEventListener'
import { useLocation } from '@reach/router'

interface LinkProps {
  activeClassName?: string
  children?: string | JSX.Element | (string | JSX.Element)[]
  className?: string
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
  state?: any
  to: string
}

const NavLink = ({
  activeClassName,
  children,
  className,
  onClick,
  state,
  to,
}: LinkProps) => {
  return (
    <Link
      activeClassName={activeClassName || `active`}
      className={`pointer-events-auto ${className}`}
      data-state={JSON.stringify(state)}
      onClick={onClick}
      state={state}
      to={to}
    >
      {children}
    </Link>
  )
}

const Navigation = ({ splashPage }: { splashPage?: boolean }) => {
  const { prismicMetadata } = useMetadata(),
    [pinned, setPinned] = useState(true),
    [width, setWidth] = useState(0),
    context = useProjectsContext(),
    projectsIndex = useContextualProjectsIndex(),
    projectsLength = useContextualProjectsLength(),
    allPrismicCategories = [`View All`, ...useAllCategories().sort()],
    allPrismicCategorySlugs = allPrismicCategories.map((slug) =>
      slug.replace(`View `, ``).toLowerCase()
    ),
    { pathname } = useLocation(),
    isCategory =
      !splashPage &&
      allPrismicCategorySlugs.findIndex(
        (slug) =>
          pathname === `/projects/${slug}` || pathname === `/projects/${slug}/`
      ) > -1,
    isIndex = pathname === `/`,
    isPage =
      !splashPage && pathname !== `/` && pathname.indexOf(`/projects`) === -1,
    currentProject = useCurrentProject(),
    currentTitle = currentProject?.data?.title,
    currentUid = currentProject?.uid,
    currentCategories = useProjectCategories(currentProject?.uid),
    currentDuration = useProjectDuration(currentProject?.uid),
    currentSiblings = useContextualProjectsSiblings(),
    isProject = !splashPage && currentProject,
    render = {
      project: currentProject,
      title: currentTitle,
      uid: currentUid,
      categories: currentCategories,
      duration: currentDuration,
    },
    scrollTop = useRef(
      typeof document !== `undefined`
        ? document?.scrollingElement?.scrollTop ?? 0
        : 0
    ),
    lastPinned = useRef(
      typeof document !== `undefined`
        ? document?.scrollingElement?.scrollTop ?? 0
        : 0
    ),
    resizeHandler = (e: Event) => {
      const newWidth = document.documentElement.clientWidth
      if (width >= 1024 && newWidth < 1024) {
        setWidth(newWidth)
        setPinned(true)
      }
      if (width < 1024 && newWidth >= 1024) {
        setWidth(newWidth)
      }
    },
    scrollHandler = useCallback(
      (e: Event) => {
        if (width < 1024) return

        const newScrollTop =
            (e?.target as Document)?.scrollingElement?.scrollTop ??
            scrollTop.current,
          scrollChange = newScrollTop - scrollTop.current

        // Update last pin reference whenever scollChange is < 0
        if (scrollChange < 0) lastPinned.current = newScrollTop

        // If newScrollTop is > 100 from last pin reference, unpin the navigation
        if (newScrollTop - lastPinned.current > 100 && pinned) setPinned(false)

        // If scrollChange is < -10, pin the navigation
        if (scrollChange < 0 && !pinned) setPinned(true)

        // Store newScrollTop
        scrollTop.current = newScrollTop ?? scrollTop.current
      },
      [pinned, width]
    )

  useEffect(() => {
    setWidth(document.documentElement.clientWidth)
  }, [setWidth])

  useEventListener(`resize`, resizeHandler)
  useEventListener(`scroll`, scrollHandler)

  return splashPage ? null : (
    <nav
      className={`transform transition duration-500 ${
        pinned ? `` : `-translate-y-full `
      } static lg:fixed left-0 top-0 right-0 flex flex-col z-20 text-xs pointer-events-none`}
    >
      {/* Project indexing */}
      {!splashPage && projectsIndex !== -1 && (
        <div className="hidden sm:block absolute right-0 p-4 text-right">
          <div>
            {projectsIndex + 1}/{projectsLength}{' '}
            {context.context === `All` ? `Projects` : context.context}
          </div>
          <div>
            <NavLink
              activeClassName=" "
              to={`/projects/${currentSiblings?.previous}`}
              state={context}
            >
              Previous
            </NavLink>
            {` `}
            <NavLink
              activeClassName=" "
              to={`/projects/${currentSiblings?.next}`}
              state={context}
            >
              Next
            </NavLink>
          </div>
        </div>
      )}
      {/* Logo */}
      <NavLink to="/" activeClassName=" ">
        <Logo
          className={`mt-4 absolute left-1/2 transform transform-translate -translate-x-1/2 w-32 fill-current`}
        />
      </NavLink>
      {/* Primary navigation */}
      <div className="p-4">
        <div className="inline-flex flex-col">
          <NavLink
            to="/"
            activeClassName=" "
            className={isIndex ? `active` : ``}
          >
            Home
          </NavLink>
          <NavLink
            to="/projects/all"
            className={isCategory || isProject ? `active` : ``}
            state={{ context: `All` }}
          >
            Projects
          </NavLink>
          <NavLink to="/info" className={isPage ? `active` : ``}>
            {' '}
            Info
          </NavLink>
          <a className="pointer-events-auto" href="https://chateauorlando.com/">
            {' '}
            Chateau Orlando
          </a>
        </div>
      </div>
      {/* Secondary navigation */}
      <div className="p-4 pt-0">
        {/* Project info */}
        <div
          className={`${
            !splashPage && !render.project
              ? `invisible transform -translate-x-4 max-h-0 max-w-0`
              : ``
          } transition-transform duration-250 relative inline-flex flex-col`}
        >
          {render.title && (
            <NavLink
              className={!splashPage ? `active` : ``}
              to={`/projects/${render.uid}`}
            >
              {render.title}
            </NavLink>
          )}
          <div className="pb-4">{render.duration}</div>
          {render.categories?.map((category, i, a) =>
            category ? (
              <NavLink
                key={category}
                to={`/projects/${category.toLowerCase()}`}
                state={{ context: category }}
              >
                {category}
              </NavLink>
            ) : null
          )}
        </div>
        {/* Category navigation */}
        <div
          className={`${
            !isCategory
              ? `invisible transform -translate-x-4 max-h-0 max-w-0`
              : ``
          } transition-transform duration-250 relative inline-flex flex-col`}
        >
          {allPrismicCategories.map((category) => (
            <NavLink
              className={
                pathname ===
                `/projects/${category.replace(`View `, ``).toLowerCase()}/`
                  ? `active`
                  : ``
              }
              to={`/projects/${category.replace(`View `, ``).toLowerCase()}`}
              key={category}
            >
              {category}
            </NavLink>
          ))}
        </div>
        {/* Info navigation */}
        <div
          className={`${
            !isPage ? `invisible transform -translate-x-4 max-h-0 max-w-0` : ``
          } transition-transform duration-250 relative inline-flex flex-col`}
        >
          {prismicMetadata?.data?.info_menu?.map((item, i) => {
            if (!item) return null
            if (item?.link?.url)
              return (
                <a
                  key={i}
                  className={`pointer-events-auto ${
                    [item.link.url, `${item.link.url}/`].indexOf(pathname) > -1
                      ? `active`
                      : ``
                  }`}
                  href={item.link.url}
                  rel="noopener"
                  target={item?.link?.target ?? `_self`}
                >
                  {item?.title}
                </a>
              )
            if (item?.title)
              return (
                <span key={i} className="pointer-events-auto">
                  {item?.title}
                </span>
              )
            return null
          })}
          <a
            className="pointer-events-auto"
            href="https://studio.sl"
            rel="noopener noreferrer"
            target="_blank"
          >
            Credits
          </a>
        </div>
      </div>
    </nav>
  )
}

export default Navigation
export { Navigation }
