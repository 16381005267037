import { graphql, useStaticQuery } from 'gatsby'
import { usePreviewData } from './usePreview'
import { PrismicMetadata } from '../../graphql-types'

const prismicMetadataQuery = graphql`
  query prismicMetadataQuery {
    prismicMetadata {
      id
      data {
        default_background_color
        default_text_color
        default_link_hover_color
        footer_content {
          html
          raw
          text
        }
        info_menu {
          title
          link {
            link_type
            target
            url
          }
        }
        site_domain
        site_title
        site_title_description
        site_title_divider
        splash_pages {
          image {
            alt
            copyright
            dimensions {
              height
              width
            }
            fluid(
              srcSetBreakpoints: [
                200
                400
                600
                800
                1200
                1600
                2000
                2400
                2800
              ]
            ) {
              ...GatsbyPrismicImageFluid
            }
            thumbnails
            url
          }
          logo_color
        }
      }
    }
  }
`

function useMetadata(): { prismicMetadata: PrismicMetadata } {
  const staticData = useStaticQuery(prismicMetadataQuery),
    mergedData = usePreviewData(staticData)

  return mergedData
}

function useSplashPages() {
  const { prismicMetadata } = useMetadata()
  return prismicMetadata?.data?.splash_pages
}

export { prismicMetadataQuery, useMetadata, useSplashPages }
