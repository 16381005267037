function determineProjectIndex(uids: any[], pathname: string): number {
  // Otherwise, if pathname doesn't contain projects get out of here
  if (pathname.indexOf(`/projects/`) !== 0) return -1
  // Finally, iterate over project uids to identify current index
  return uids.findIndex(
    (uid: any) =>
      pathname === `/projects/${uid}` || pathname === `/projects/${uid}/`
  )
}

export { determineProjectIndex }
